<template>
  <div ref="qrcode" id="qrcode"></div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  props: ['url', 'codeSize'],
  data () {
    return {
      qrcode: {},
      imgUrl: ''
    }
  },
  mounted () {
    this.qrcodeScan(this.url)
  },
  methods: {
    // url转二维码
    qrcodeScan (url) { // 生成二维码
      // this.$refs.qrcode.innerHTML = ''
      this.qrcode = new QRCode(this.$refs.qrcode, {
        // width: this.preview ? 180: 100,  // 二维码宽度
        // height: this.preview ? 180: 100, // 二维码高度
        width: this.codeSize, // 二维码宽度
        height: this.codeSize, // 二维码高度
        text: url,
        correctLevel: 1
      })
      console.log('this.qrcode:', this.qrcode)
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
