import Request from '@/lib/Request'
// const http = new Request('http://192.168.1.80:9003', 3000)
// const http = new Request('http://192.168.9.150:9003', 3000)
// const http = new Request('https://cubewebapi.51ylk.com', 3000)
const http = new Request(process.env.VUE_APP_URL)

// 加入购物车
export const addCar = params => {
  return http.post('/cube-client/order/shopping/cart/add', params)
}
// 查
export const getList = params => {
  return http.post('/cube-client/order/shopping/cart/page', params)
}
// 修改购物车数量
export const updateAmount = data => {
  return http.put('/cube-client/order/shopping/cart/updateQuantity', {}, data)
}
// 删除
export const deletePrize = data => {
  return http.delete('/cube-client/order/shopping/cart/delete', {}, data)
}
// 提交订单
export const sendOrder = params => {
  return http.post('/cube-client/order/shopping/order/submit', params)
}
// 获取支付信息
export const getPayInfo = params => {
  return http.post('/cube-client/order/shopping/order/createPay', params)
}
// 查订单状态
export const getOrderStatus = params => {
  return http.post('/cube-client/order/shopping/order/checkOrderStatus', params)
}
// 查订单
export const getOrderList = params => {
  return http.post('/cube-client/order/shopping/order/queryPage', params)
}
// 取消订单
export const cancelOrder = data => {
  return http.put('/cube-client/order/shopping/order/cancel', {}, data)
}
// 修改订单
export const updateOrder = data => {
  return http.post('/cube-client/order/shopping/order/applyForInvoice', data)
}
