var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prizeOrder" },
    [
      _c("h3", [_vm._v("模板订单")]),
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-input",
            {
              attrs: { maxlength: "50", placeholder: "请输入订单号或模板名称" },
              model: {
                value: _vm.searchContent,
                callback: function($$v) {
                  _vm.searchContent = $$v
                },
                expression: "searchContent"
              }
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.search },
                slot: "append"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "orderNo",
              "header-align": "center",
              align: "center",
              label: "订单号"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "模板类型",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.templeBaseType == 0
                              ? "设计模板"
                              : "活动模板"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "templeName",
              "header-align": "center",
              align: "center",
              label: "模板名称",
              width: "280"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "templeSellingPrice",
              "header-align": "center",
              align: "center",
              label: "模板金额",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "templeDiscountPrice",
              "header-align": "center",
              align: "center",
              label: "优惠金额",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "payAmount",
              "header-align": "center",
              align: "center",
              label: "实付金额",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: { "header-align": "center", align: "center", label: "付款" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("filterTime")(scope.row.payTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "订单状态"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.orderStatusList[scope.row.orderStatus]))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { "header-align": "center", align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    (scope.row.orderStatus == 1 ||
                      scope.row.orderStatus == 4) &&
                    scope.row.invoiceStatus == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.getInvoiceList(scope.row)
                              }
                            }
                          },
                          [_vm._v("申请开票")]
                        )
                      : (scope.row.orderStatus == 1 ||
                          scope.row.orderStatus == 4) &&
                        scope.row.invoiceStatus == 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#606266" },
                            attrs: { type: "text", size: "small" }
                          },
                          [_vm._v("待开票")]
                        )
                      : (scope.row.orderStatus == 1 ||
                          scope.row.orderStatus == 4) &&
                        scope.row.invoiceStatus == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.downloadInvoice(scope.row.invoiceUrl)
                              }
                            }
                          },
                          [_vm._v("下载发票")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.getOrderDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("订单明细")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticClass: "page",
        attrs: {
          "current-page": _vm.page,
          "page-sizes": [10, 20, 50, 100],
          "page-size": _vm.limit,
          total: _vm.totalPage,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "size-change": _vm.sizeChangeHandle,
          "current-change": _vm.currentChangeHandle
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "支付订单",
            visible: _vm.payVisible,
            "append-to-body": "",
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.payVisible = $event
            }
          }
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.payType,
                callback: function($$v) {
                  _vm.payType = $$v
                },
                expression: "payType"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "微信支付", name: "first" } },
                [
                  _c(
                    "div",
                    { staticClass: "alipay" },
                    [
                      _c("p", [
                        _vm._v(" 应付金额："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s("￥" + _vm.money + "元"))
                        ])
                      ]),
                      _c("active-qrcode", {
                        attrs: { url: _vm.payCode, preview: true }
                      }),
                      _c("p", [
                        _c("img", {
                          staticStyle: { "margin-right": "5px" },
                          attrs: {
                            src: require("../../../assets/img/marketing/wx.png"),
                            alt: "",
                            width: "25px"
                          }
                        }),
                        _vm._v(" 微信扫码付款 ")
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单明细",
            visible: _vm.orderVisible,
            "append-to-body": "",
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.orderVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "info-area" }, [
            _c("h4", { staticClass: "title-name" }, [_vm._v("基本信息")]),
            _c("div", { staticClass: "detail-info" }, [
              _c("div", { staticClass: "detail-item" }, [
                _vm._v("订单号：" + _vm._s(_vm.detail.orderNo))
              ]),
              _c("div", { staticClass: "detail-item" }, [
                _vm._v(
                  " 订单状态：" +
                    _vm._s(_vm.orderStatusList[_vm.detail.orderStatus]) +
                    " "
                )
              ]),
              _c("div", { staticClass: "detail-item" }, [
                _vm._v(
                  " 模板类型： " +
                    _vm._s(
                      _vm.detail.templeBaseType == 0 ? "设计模板" : "活动模板"
                    ) +
                    " "
                )
              ]),
              _c("div", { staticClass: "detail-item" }, [
                _vm._v("模板名称：" + _vm._s(_vm.detail.templeName))
              ]),
              _c("div", { staticClass: "detail-item" }, [
                _vm._v(
                  " 模板金额：" + _vm._s(_vm.detail.templeOriginalPrice) + " "
                )
              ]),
              _c("div", { staticClass: "detail-item" }, [
                _vm._v(
                  " 优惠金额：" + _vm._s(_vm.detail.templeDiscountPrice) + " "
                )
              ]),
              _c("div", { staticClass: "detail-item" }, [
                _vm._v("实付金额：" + _vm._s(_vm.detail.payAmount))
              ]),
              _c("div", { staticClass: "detail-item" }, [
                _vm._v(
                  " 支付方式：" +
                    _vm._s(
                      _vm.detail.payType
                        ? _vm.payTypeList[_vm.detail.payType - 1]
                        : "未支付"
                    ) +
                    " "
                )
              ]),
              _c("div", { staticClass: "detail-item" }, [
                _vm._v(
                  " 下单时间：" +
                    _vm._s(_vm._f("filterTime")(_vm.detail.createTime)) +
                    " "
                )
              ]),
              _vm.detail.payTime
                ? _c("div", { staticClass: "detail-item" }, [
                    _vm._v(
                      " 支付时间：" +
                        _vm._s(_vm._f("filterTime")(_vm.detail.payTime)) +
                        " "
                    )
                  ])
                : _c("div", { staticClass: "detail-item" }, [
                    _vm._v("支付时间：未支付")
                  ])
            ]),
            _c("h4", { staticClass: "title-name" }, [_vm._v("发票信息")]),
            _c("div", { staticClass: "detail-info" }, [
              _vm.detail.invoiceStatus == 0
                ? _c("div", { staticClass: "detail-item" }, [
                    _vm._v(" 暂不开票 ")
                  ])
                : _vm.detail.invoiceStatus != 0 && _vm.detail.invoiceInfo
                ? _c("div", [
                    JSON.parse(_vm.detail.invoiceInfo).ifVat == 1
                      ? _c("div", [
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v("发票类型：增值普通发票")
                          ]),
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v(
                              " 公司名称：" +
                                _vm._s(
                                  JSON.parse(_vm.detail.invoiceInfo)
                                    .vatinvoiceInfo
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v(
                              " 纳税人识别号：" +
                                _vm._s(
                                  JSON.parse(_vm.detail.invoiceInfo)
                                    .vatTaxpayerNum
                                ) +
                                " "
                            )
                          ])
                        ])
                      : JSON.parse(_vm.detail.invoiceInfo).ifVat == 2
                      ? _c("div", [
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v("发票类型：增值专用发票")
                          ]),
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v(
                              " 单位名称：" +
                                _vm._s(
                                  JSON.parse(_vm.detail.invoiceInfo)
                                    .vatInvoiceTitle
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v(
                              " 注册地址：" +
                                _vm._s(
                                  JSON.parse(_vm.detail.invoiceInfo)
                                    .vatCompanyAddress
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v(
                              " 注册电话：" +
                                _vm._s(
                                  JSON.parse(_vm.detail.invoiceInfo).vatTelphone
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v(
                              " 开户银行：" +
                                _vm._s(
                                  JSON.parse(_vm.detail.invoiceInfo).vatBankName
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v(
                              " 银行账户：" +
                                _vm._s(
                                  JSON.parse(_vm.detail.invoiceInfo)
                                    .vatBankAccount
                                ) +
                                " "
                            )
                          ])
                        ])
                      : JSON.parse(_vm.detail.invoiceInfo).ifVat == 3
                      ? _c("div", [
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v("发票类型：个人")
                          ]),
                          _c("div", { staticClass: "detail-item" }, [
                            _vm._v(
                              " 抬头名称：" +
                                _vm._s(
                                  JSON.parse(_vm.detail.invoiceInfo)
                                    .vatInvoiceTitle
                                ) +
                                " "
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ])
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发票信息",
            visible: _vm.invoiceVisible,
            "append-to-body": "",
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.invoiceVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-bottom": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/PersonalCenter/InvioceInfo")
                    }
                  }
                },
                [_vm._v("管理发票")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.invoiceData, border: "", "show-header": false }
            },
            [
              _c("el-table-column", {
                attrs: { width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.$index },
                            nativeOn: {
                              change: function($event) {
                                return _vm.getCurrentRow(scope.row)
                              }
                            },
                            model: {
                              value: _vm.currentRow,
                              callback: function($$v) {
                                _vm.currentRow = $$v
                              },
                              expression: "currentRow"
                            }
                          },
                          [_vm._v(_vm._s())]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.invoiceTypeList[scope.row.ifVat - 1])
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", { attrs: { prop: "vatInvoiceTitle" } })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sub } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }